<template>
  <access-control />
</template>

<script>
import AccessControl from "../../components/solutions/intelligent-security/AccessControl.vue";
export default {
  components: { AccessControl },
};
</script>
