<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-yellow h1 mb-3">Translation Of Card Information</h2>
      <p class="desc my-3">
        It is also one of the access control solutions. They are congenial with
        much OEM access system, can ready any time of card, including the credit
        card and advanced magnetic stripe card. The reader has the ability to
        translate any kind of information from the card into the chosen formats
        per the wish of OEM’s controller.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/intelligent-security/access-control/access-control-solutionimg-2.png"
        alt="Explain Storage?"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "second-access-section",
};
</script>
