<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1 mb-3">Apollo Security</h2>
      <p class="desc my-3">
        Apollo Security is an established leader in the AccessControl Security
        industry, providing superior hardware products supported by innovative
        software solutions for over 20 years. A world-wide network of sales
        offices and dealers ensures comprehensive support for all types of
        installations.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/intelligent-security/access-control/access-control-solutionimg-1.png"
        alt="Explain Storage?"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "first-access-section",
};
</script>
